import CallToActionFooter from "../CallToActionFooter";
import Container from "react-bootstrap/Container";
import React from "react";
import PageWithHeaderAndFooter from "../PageWithHeaderAndFooter";
import InfoRow from "./InfoRow";
import blackAndYellowSweaterBlackHair from "../../static/black-and-yellow-sweater-black-hair.png";
import Image from "react-bootstrap/Image";
import willGoldbloom from "../../static/will-goldbloom.jpg";

export default function AboutUs() {
  return (
    <PageWithHeaderAndFooter>
      <Container>
        <div className="my-5">
          <h1 className="text-center">About Us</h1>

          <div className="my-5">
            <h4>Who We Are</h4>
            <p>
              Canary was founded in 2021 as an affordable ombuds system that
              helps employees and employers address workplace conflict and
              misconduct
            </p>
            <p>
              Canary is the brainchild of WG Resolutions, a consulting firm that
              focuses on workplace investigations, training and mediation, that
              has serviced over a hundred clients since it was founded in 2020
            </p>
          </div>

          <div className="my-5">
            <h4>The Story</h4>
            <p>
              Will Goldbloom, a lawyer and workplace investigator, hated seeing
              a recurring pattern in his work: employers using a workplace
              investigator to address misconduct or interpersonal conflict among
              employees that could have been addressed using either preventative
              training or informal conflict resolution.
            </p>
            <p>
              In 2019 he ran into Tom Skopek and Vlad Duta, two friends from
              high school, and told them about his idea to create an anonymous
              complaint system that helped employees resolve their problems
              without triggering a costly workplace investigation and giving
              employers the information they need to design helpful training for
              their employees.
            </p>
            <p>
              Since then, Will has helped clients use Canary as their go-to
              system for employees to raise concerns and give them the resources
              they need to resolve them.
            </p>
          </div>

          <div className="d-flex flex-row justify-content-center">
            <div
              className="d-flex rounded-circle overflow-hidden justify-content-center align-items-center"
              style={{ height: "200px", width: "200px" }}
            >
              <Image src={willGoldbloom} style={{ height: "200px" }} />
            </div>
          </div>

          <CallToActionFooter
            leftText1={"Contact us for demo"}
            leftButtonText={"Schedule a demo"}
            leftHref={"/bookConsultation"}
            rightText1={"Got questions?"}
            rightButtonText={"Answer my questions"}
            rightHref={"/faq"}
          />
        </div>
      </Container>
    </PageWithHeaderAndFooter>
  );
}
