import React from "react";
import Container from "react-bootstrap/Container";
import PageWithHeaderAndFooter from "../PageWithHeaderAndFooter";
import Image from "react-bootstrap/Image";
import willGoldbloom from "../../static/will-goldbloom.jpg";
import { Link } from "react-router-dom";
import Button from "../Button";
import useWindowWidth from "../../hooks/useWindowWidth";

export default function ContactCanaryAdvisor() {
  const { isMobile } = useWindowWidth();
  return (
    <PageWithHeaderAndFooter>
      <Container>
        <div className="d-flex flex-column my-5">
          <div className="mb-5">
            <h1 className="text-center">Canary Advisors</h1>
          </div>
          <div
            className={`d-flex ${
              isMobile ? "flex-column" : "flex-row justify-content-center"
            } align-items-center`}
          >
            <div
              className="d-flex rounded-circle overflow-hidden justify-content-center align-items-center"
              style={{ height: "200px", width: "200px" }}
            >
              <Image src={willGoldbloom} style={{ height: "200px" }} />
            </div>
            <div
              className={`d-flex flex-column justify-content-between mx-5 ${
                isMobile ? "align-items-center pt-3" : ""
              }`}
            >
              <h4>Will Goldbloom</h4>
              <Link to="/bookConsultation">
                <Button size={"md"} variant="primary">
                  Book an appointment
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </PageWithHeaderAndFooter>
  );
}
