import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import Logo from "./Logo";
import { useLocation } from "react-router-dom";

export default function AppHeader() {
  const location = useLocation();

  const navLinks = [
    {
      href: "/how",
      title: "How it Works",
    },
    {
      href: "/prices",
      title: "Pricing",
    },
    {
      href: "/contactAdvisor",
      title: "Contact Advisor",
    },
    {
      href: "/why",
      title: "Why Canary",
    },
    {
      href: "/about",
      title: "About Us",
    },
    {
      href: "/app/login",
      title: "Employer Login",
    },
  ];

  return (
    <Navbar collapseOnSelect expand="lg">
      <Navbar.Brand href="/">
        <Logo md includeText={true} className="d-inline-block align-top" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">
          {navLinks.map((link) => (
            <Nav.Link
              key={link.href}
              href={link.href}
              active={location.pathname === link.href}
              className="ml-2"
              style={{ fontSize: "0.95em" }}
            >
              {link.title}
            </Nav.Link>
          ))}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
