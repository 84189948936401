import CallToActionFooter from "../../CallToActionFooter";
import Container from "react-bootstrap/Container";
import React from "react";
import PageWithHeaderAndFooter from "../../PageWithHeaderAndFooter";
import InfoRow from "../InfoRow";
import threePeopleScarves from "../../../static/three-people-scarves.png";
import yellowShirtBlackHair from "../../../static/yellow-shirt-black-hair.png";
import blackShirtKneeling from "../../../static/black-shirt-kneeling.png";
import groupAnon from "../../../static/group-anon.png";
import { Link } from "react-router-dom";
import ComparisonCaseStudy from "./ComparisonCaseStudy";
import SmallText from "../../SmallText";
import Button from "../../Button";
import "./WhyCanary.scss";
import Spacer from "../../Spacer";
import { FcAssistant, FcLock, FcIdea, FcBullish } from "react-icons/fc";
import Card from "../../Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useWindowWidth from "../../../hooks/useWindowWidth";

const CONTENT = [
  {
    title:
      "Most complaints reported to employers are about harassment, discrimination and other HR issues. That's why Canary is designed to deal with these types of problems.",
    text: (
      <>
        <p>
          According to a study of workplace complaints, between 65% and 72% of
          all workplace complaints are about respect, diversity and HR issues.*
        </p>
        <p>
          Wouldn't it make more sense for these types of people-centred
          complaints to be worked through with a professional who knows about
          conflict resolution and misconduct, and can also provide coaching? A
          great reason to choose Canary.
        </p>
      </>
    ),
  },
  {
    title:
      "Canary costs less than anonymous complaint systems. But Canary gives you more value for your money. Employees get access to a real person who can help them address their concerns instead of just reporting them.",
    text: (
      <>
        <p>
          According to a study of anonymous complaint systems, organizations
          receive about 1.5 anonymous complaints per 100 employees.*
        </p>

        <p>
          So why pay for a system that can handle an unlimited number of
          complaints when you are likely to get so few? Instead, wouldn't it
          make more sense to pay for real people helping those employees work
          through their problems?
        </p>
      </>
    ),
  },
  {
    title:
      "Canary is an independent conflict resolution system for your organization. These systems are proven to reduce litigation costs and improve the day-to-day working lives of employees.",
    text: (
      <>
        <p>
          When employers use an informal conflict resolution system like an
          ombudsperson, employees report better relationships with their
          colleagues - including those they are in conflict with - and fewer
          working hours spent dealing with interpersonal conflict.**
        </p>
        <p>
          Conventional anonymous complaint systems don't help with resolving
          conflict. Canary does.
        </p>
      </>
    ),
  },
  {
    title:
      "Canary actually protects employees anonymity when they raise concerns, which makes them more likely to speak up.",
    text: (
      <>
        <p>
          The more anonymous employees can be when they raise concerns, the
          safer they feel.***
        </p>

        <p>
          When employees submit complaints using an anonymous complaint system,
          they might accidentally reveal their identity.
        </p>

        <p>
          Canary helps employees remain anonymous by discussing their complaint
          with them in detail, and ensuring they report what they want their
          employer to do about their concerns. That's what you get when your
          anonymous complaint system is run by humans who are experienced in
          dealing with workplace conflict.
        </p>
      </>
    ),
  },
];

export default function WhyCanary() {
  const { isMobile } = useWindowWidth();
  return (
    <PageWithHeaderAndFooter>
      <Container>
        <div className="align-items-center text-center py-5">
          <h1 className="mb-4">Why Canary?</h1>
          <p>
            There are so many different types of{" "}
            <span className="text-info">"speak up"</span> tools for employers
          </p>
          <p>
            <strong>We think Canary is one of the best</strong> (but we are
            biased)
          </p>
          <p>
            Canary is distinct because it gives employees access to a person who
            can help them understand their workplace's policies and how to
            address their concerns both formally and informally
          </p>
        </div>

        <Row className="d-flex flex-row">
          <Col>
            <Card className="mx-2 my-2 flex-fill" style={{ minHeight: "16em" }}>
              <h4>Real People</h4>
              <p style={{ fontSize: "0.8em" }}>
                Every employees has access to an experienced workplace
                investigator to guide them through their report
              </p>
              <div>
                <FcAssistant style={{ fontSize: "4em" }} />
              </div>
            </Card>
          </Col>
          <Col>
            <Card className="mx-2 my-2 flex-fill" style={{ minHeight: "16em" }}>
              <h4>Enhanced Privacy</h4>
              <p style={{ fontSize: "0.8em" }}>
                Since the Canary Advisor is the one reporting, employees can't
                accidentally identify themselves
              </p>
              <FcLock style={{ fontSize: "4em" }} />
            </Card>
          </Col>
          <Col>
            <Card className="mx-2 my-2 flex-fill" style={{ minHeight: "16em" }}>
              <h4>Reporting</h4>
              <p style={{ fontSize: "0.8em" }}>
                Employers get access to reports that show the number of
                complaints in aggregated anonymized form
              </p>
              <FcBullish style={{ fontSize: "4em" }} />
            </Card>
          </Col>
        </Row>

        <div className="align-items-center text-center py-5">
          <p>
            <i>Still not convinced?</i> Here are some reasons to choose Canary:
          </p>
        </div>

        {/*Numbered list of reasons to choose Canary*/}
        {CONTENT.map((content, index) => (
          <>
            <div
              className={`bullet-item-container d-flex ${
                isMobile ? "flex-column" : "flex-row "
              } align-items-center`}
            >
              <div className="mr-5">
                <h1 className="list-number-text">{index + 1}</h1>
              </div>
              <div className={isMobile ? "mx-2" : ""}>
                <h5>
                  <strong>{content.title}</strong>
                </h5>
                {content.text}
              </div>
            </div>
            <Spacer height={40} />
          </>
        ))}

        <div className="d-flex justify-content-center">
          <Link to="/bookConsultation">
            <Button variant="primary" xl active>
              I'm interested
            </Button>
          </Link>
        </div>

        <div className="py-5">
          <ComparisonCaseStudy />
        </div>

        <div>
          <p>
            <u>Footnotes</u>
          </p>
          <SmallText>
            *NAVEX 2020 and 2021 Risk & Compliance Hotline Benchmark Reports.
          </SmallText>
          <SmallText>
            ** Solarz, B. J., & Gaspar, A. (2019). Three insights, two programs,
            one theory: Transformative practices as opportunities for moral
            growth in the healthcare workplace. Conflict Resolution Quarterly,
            37(1), 67-78.
          </SmallText>
          <SmallText>
            *** Mao, C. M., & DeAndrea, D. C. (2019). How anonymity and
            visibility affordances influence employees’ decisions about voicing
            workplace concerns. Management Communication Quarterly, 33(2),
            160-188.
          </SmallText>
        </div>

        <CallToActionFooter
          leftText1={"More questions about Canary?"}
          leftButtonText={"Answer my questions"}
          leftHref={"/faq"}
          rightText1={"Ready to see Canary in action?"}
          rightButtonText={"Schedule a demo"}
          rightHref={"/bookConsultation"}
        />
      </Container>
    </PageWithHeaderAndFooter>
  );
}
