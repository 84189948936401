import React from "react";
import Container from "react-bootstrap/Container";
import CallToActionFooter from "../CallToActionFooter";
import SimpleAccordion from "../SimpleAccordion";
import Spacer from "../Spacer";
import PageWithHeaderAndFooter from "../PageWithHeaderAndFooter";

export default function Faq() {
  return (
    <PageWithHeaderAndFooter>
      <Container>
        <h1 className="my-5 text-center">Frequently Asked Questions</h1>

        {/* General Questions */}
        <h3 className="mt-5 mb-4">General Questions</h3>
        <SimpleAccordion
          title="Why doesn't Canary have a web-based platform where employees can log on and submit complaints?"
          paragraph1="Submitting a complaint on a web-based form is like writing a long email to an ex and clicking send a little too quickly. Not the best idea."
          paragraph2="We think that employees are served better when they get the chance to talk through their complaint and decide what information they want to submit to their employer, and what they want to keep to themselves. This gives employees more control over their complaint and makes them better informed about how it might be addressed."
        />
        <SimpleAccordion
          title="Our employees are already engaged, happy, and feel comfortable raising concerns with HR. Do we really need this?"
          paragraph1="Sometimes even happy and engaged employees can experience interpersonal conflict and workplace stress. Employees may feel even more engaged if they know that there's someone independent of the organization who they can talk to in case something happens."
          paragraph2="If you've got a workplace where people always raise concerns with HR you're in luck -  beyond the basic fee, you only pay for Canary when it's used."
        />
        <SimpleAccordion
          title="What does Canary do that HR can't do?"
          paragraph1="That depends on what your employer's HR already does! Sometimes there is just not enough time or bandwidth for HR to address interpersonal conflict and coach employees. That's where we come in."
          paragraph2="Even if that's something your HR does do, HR can't have off-the-record conversations when it relates to harassment and discrimination. If they hear about something, they have to act. Canary gives employees an opportunity to have an off-the-record discussion before they decide what to do."
        />
        <SimpleAccordion
          title="Is Canary secure?"
          paragraph1="Definitely! Access to information by any third party is prevented by encrypting data in-transit (end-to-end) using secure TLS cryptographic protocols (TLS 1.2), and Advanced Encryption Standard (AES) with a 256-bit key to encrypt data at rest including the backups of the information. TLS is used to secure all data in transit. We could go on..."
        />
        <SimpleAccordion
          title="Does Canary investigate complaints?"
          paragraph1="Nope. Canary only helps employees by giving them tools to resolve their concerns informally, or giving them information about how to raise a formal complaint, including what an investigation will look like. The main goal of Canary is to give employees a sense of control over their concerns and provide them with the information they need to resolve them in a way that feels most comfortable for them."
        />
        <SimpleAccordion
          title="What kind of information is on the Canary dashboard?"
          paragraph1="The dashboard gives employers a record of the type of call that comes through and as much information about the employee's concerns as the employee is willing to provide."
          paragraph2="You'll always get helpful data on what types of concerns are coming forward, why the person doesn't feel comfortable speaking to HR, and how they want their concern resolved."
        />
        <SimpleAccordion
          title="Can employers contact employees anonymously after they have submitted a complaint?"
          paragraph1="Canary Advisors will ask employees if they want an employer representative to talk to them about their concerns. If they do, Canary Advisorswill assist employees with creating an anonymous email address, which they will include in the complaint submitted to the employer, so they can correspond with each other."
        />
        <SimpleAccordion
          title="Can't employees submit false reports?"
          paragraph1="Employees can always submit false reports to their employer. Whether it's by snail mail or an anonymous Twitter account. With Canary, employees have to speak to a human when they raise concerns. A Canary Advisor will need to ask them questions about their concerns. By trying to understand their concerns in detail, it is harder for employees to submit false reports."
        />

        {/* Questions about Canary Advisors */}
        <h3 className="mt-5 mb-4">Questions about Canary Advisors</h3>
        <SimpleAccordion
          title="What happens when an employee calls a Canary Advisor?"
          paragraph1="First, the Canary Advisor will ask what the employee is looking for - to report a concern, to get some coaching, or both."
          paragraph2="Then the Canary Advisor will listen to the employee's situation and gain an understanding of the employee's goals for overcoming or addressing their situation. The Canary Advisor will outline some options for the employee, including strategies for resolving their concerns informally and the opportunity to receive coaching, or instructions on how to raise a formal complaint based on their workplace's policy."
          paragraph3="The Canary Advisor will then ask the employee if they want to submit any information to their employer about their concerns, and help them do so in a way that does not reveal their identity."
        />
        <SimpleAccordion
          title="What kind of advice do Canary Advisors give employees? Is it legal advice?"
          paragraph1="Canary Advisors do not give legal advice. They simply help employees understand and address concerns that they have in the workplace, and ensure that they understand what their workplace policy says about those concerns."
          paragraph2="The kind of advice that Canary Advisors give is based on their expertise in workplace conflict - not workplace law."
        />
        <SimpleAccordion
          title="How is a Canary Advisors different from a counsellor from an Employee Assistance Program (EAP)?"
          paragraph1="Have you ever tried to call EAP to discuss workplace conflict or misconduct? We have. We've learned that this is not an EAP counsellor's area of expertise. They are better at providing general counselling and referrals to other resources. EAP and Canary each have their own strengths."
          paragraph2="EAP counsellors also do not help employees relay concerns to their employers anonymously."
        />
        <SimpleAccordion
          title="What qualifications do Canary Advisors have?"
          paragraph1="Canary Advisors all receive training to ensure that their approach is consistent. They have a variety of backgrounds but all have past experience and education in the area of mediation, coaching, and workplace conflict management. They know what it means to be both empathetic and neutral."
        />
        <SimpleAccordion
          title="What happens when an employee gets coaching from a Canary Advisor?"
          paragraph1="Canary Advisors always offer coaching in some form. Even if it's just helping an employee understand and consider the options available to them for addressing their concerns. When an employee wants ongoing coaching, a Canary Advisor will gather information about the employee's goals for overcoming those concerns, and all the possible pathways to get there. The Canary Advisor then works with the employee to help them be accountable for their desired goals - and their chosen pathway to address them - by creating milestones for each follow-up coaching session."
        />
        <SimpleAccordion
          title="How many coaching sessions can an employee receive?"
          paragraph1="In order to make sure that employees all get a chance to use Canary, we limit the number of coaching sessions each employee can receive. We also think that three sessions is often sufficient. If it's not, we refer employees to different coaches or suggest that they contact their employer to see what other coaching programs they may have access to."
        />

        {/* Questions about Pricing */}
        <h3 className="mt-5 mb-4">Questions about Pricing</h3>
        <SimpleAccordion
          title="How many calls do employees make to Canary?"
          paragraph1="It really depends. But based on the average number of complaints received by most organizations, the range is normally between 1 and 10 per 100 employees. You as the employer are the best person to be able to answer this question based on your experience with your employees. You're always welcome to change your plan part-way through the year. You can start with pay-as-you-go and then decide to purchase packages of calls."
        />
        <SimpleAccordion
          title="Does every call to Canary count as a reporting or coaching call?"
          paragraph1="No way! Calls that are made just for informational purposes - to ask about what Canary does and how it works - do not count as reporting or coaching calls. It only counts as a reporting call when an employee raises a concern and engages in meaningful conversation with the Canary ombuds about that concern."
        />
        <SimpleAccordion
          title="Do you give discounts for non-profits?"
          paragraph1="We certainly do. Contact us for more information about the discounts we offer."
        />
        <SimpleAccordion
          title="Do you get to carry unused calls from one year to the next year?"
          paragraph1="We do not allow you to carry over unused calls from one year to the next."
        />

        {/* Still have more questions? */}
        <div className="font-weight-bold my-5">
          <p className="text-center">
            Still have questions? Email us at info@canaryatwork.com
          </p>
        </div>
        <Spacer height={40} />
        <CallToActionFooter
          leftText1={"Want to know more?"}
          leftButtonText={"Schedule a demo"}
          leftHref={"/bookConsultation"}
          rightText1={"Transparent Pricing"}
          rightButtonText={"Let's see prices"}
          rightHref={"/prices"}
        />
      </Container>
    </PageWithHeaderAndFooter>
  );
}
