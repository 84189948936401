import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import "./App.css";
import ContactCanaryAdvisor from "./components/pages/ContactCanaryAdvisor";
import EmployerLogin from "./components/pages/EmployerLogin";
import Signup from "./components/pages/Signup";
import Logout from "./components/pages/Logout";
import NotFound from "./components/pages/NotFound";
import PrivateRoute from "./components/PrivateRoute";
import AdminPanel from "./components/pages/adminPanel/AdminPanel";
import Home from "./components/pages/Home/Home";
import Footer from "./components/Footer";
import RespondentLogin from "./components/pages/RespondentLogin";
import OrganizationHome from "./components/pages/organization/OrganizationHome";
import OrganizationReport from "./components/pages/organization/OrganizationReport";
import OrganizationChooseReport from "./components/pages/organization/OrganizationChooseReport";
import InterestedEmployer from "./components/pages/InterestedEmployer";
import InterestedRespondent from "./components/pages/InterestedRespondent";
import FreeWorkplaceSurvey from "./components/pages/FreeWorkplaceSurvey";
import InterestedEmployerAdditionalInfo from "./components/pages/InterestedEmployerAdditionalInfo";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import TermsOfService from "./components/pages/TermsOfService";
import LearnMore from "./components/pages/LearnMore/index";
import BookConsultation from "./components/pages/BookConsultation";
import BookCoachingSession from "./components/pages/BookCoachingSession";
import Faq from "./components/pages/Faq";
import Prices from "./components/pages/Prices";
import WhyCanary from "./components/pages/WhyCanary/WhyCanary";
import AboutUs from "./components/pages/AboutUs";
import HowItWorks from "./components/pages/HowItWorks";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/app/login" component={EmployerLogin} />
        <Route path="/app/signup" component={Signup} />
        <Route path="/app/logout" component={Logout} />
        <PrivateRoute type="admin" path="/app" component={AdminPanel} />
        <PrivateRoute
          type="organization"
          path="/org/:orgId/report/:formId"
          component={OrganizationReport}
        />
        <PrivateRoute
          type="organization"
          path="/org/:orgId/chooseReport"
          component={OrganizationChooseReport}
        />
        <PrivateRoute
          type="organization"
          path="/org/:orgId"
          component={OrganizationHome}
        />
        <Route
          exact
          path="/interestedEmployer"
          component={InterestedEmployer}
        />
        <Route
          exact
          path="/interestedEmployerAdditionalInfo"
          component={InterestedEmployerAdditionalInfo}
        />
        <Route
          exact
          path="/interestedRespondent"
          component={InterestedRespondent}
        />
        <Route
          exact
          path="/freeWorkplaceSurvey"
          component={FreeWorkplaceSurvey}
        />
        <Route exact path="/terms" component={TermsOfService} />
        <Route exact path="/privacy" component={PrivacyPolicy} />
        <Route exact path="/anonymousReport" component={RespondentLogin} />
        <Route exact path="/learnMore" component={LearnMore} />
        <Route exact path="/bookConsultation" component={BookConsultation} />
        <Route
          exact
          path="/bookCoachingSession"
          component={BookCoachingSession}
        />
        <Route path="/prices" component={Prices} />
        <Route path="/faq" component={Faq} />
        <Route path="/how" component={HowItWorks} />
        <Route path="/why" component={WhyCanary} />
        <Route path="/about" component={AboutUs} />
        <Route path="/contactAdvisor" component={ContactCanaryAdvisor} />
        <Route path="/" component={Home} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
