import React from "react";
import BookCalendlyAppointment from "./BookCalendlyAppointment";
import PageWithHeaderAndFooter from "../PageWithHeaderAndFooter";

const CALENDLY_CONSULTATION_LINK = "https://calendly.com/canaryconsult/30min";

export default function BookConsultation() {
  return (
    <PageWithHeaderAndFooter>
      <BookCalendlyAppointment calendlyLink={CALENDLY_CONSULTATION_LINK} />;
    </PageWithHeaderAndFooter>
  );
}
