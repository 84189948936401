import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import "./SimpleAccordion.scss";

export default function SimpleAccordion({
  title,
  paragraph1,
  paragraph2,
  paragraph3,
}) {
  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <Accordion
      className="customAccordion shadow-sm"
      style={{ marginBottom: 10 }}
    >
      <Card style={{ borderColor: "black" }}>
        <Accordion.Toggle
          as={Card.Header}
          eventKey={true}
          onClick={() => {
            setAccordionOpen(!accordionOpen);
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              cursor: "pointer",
              fontWeight: "bold",
              borderBottom: "black",
            }}
          >
            <div style={{ flexBasis: "90%" }}>{title}</div>
            <div
              className={accordionOpen === true ? "flipVertically" : ""}
              style={{ alignSelf: "center" }}
            >
              &#9660;
            </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={true}>
          <Card.Body>
            <div>
              <div className="mb-3">{paragraph1}</div>
              {paragraph2 && <div className="mb-3">{paragraph2}</div>}
              {paragraph3 && <div className="mb-3">{paragraph3}</div>}
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}
