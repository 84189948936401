import React, { useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CallToActionFooter from "../CallToActionFooter";
import Spacer from "../Spacer";
import Container from "react-bootstrap/Container";
import useWindowWidth from "../../hooks/useWindowWidth";
import InfoRow from "./InfoRow";
import blackShirtBlondeHair from "../../static/black-shirt-blonde-hair.png";
import PageWithHeaderAndFooter from "../PageWithHeaderAndFooter";
import "./Prices.scss";
import { Link } from "react-router-dom";
import Button from "../Button";

export default function Prices() {
  const { isMobile } = useWindowWidth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageWithHeaderAndFooter>
      <Container>
        <div className="pt-5">
          <h1 className="text-center mb-5">
            Pick the plan that's right for you
          </h1>

          {/*Price Options*/}
          <Row
            className={`bg-primary ${isMobile ? "py-2 px-2" : "py-5 px-5"}`}
            style={{ borderRadius: "1.5em" }}
          >
            {/*Starter*/}
            <Col
              className={`border py-3 px-2 bg-white mx-3 my-3`}
              style={{ borderRadius: "1.5em" }}
            >
              <div className="border-bottom">
                <h5 className={"text-center"}>Starter</h5>
                <h1 className="price-text">
                  <span style={{ fontSize: "0.4em" }}>$</span>100
                  {/*// A hack to center the price*/}
                  <span style={{ fontSize: "0.5em", visibility: "hidden" }}>
                    $
                  </span>
                </h1>
                <p className="text-center" style={{ fontSize: "0.70em" }}>
                  CAD per month
                </p>
                <p className="text-center">
                  Good for companies getting started
                </p>
              </div>
              <div className="mt-3 ml-4">
                <p className="text-left">
                  <span className="number-text">10</span> free calls
                </p>
                <p className="text-left">
                  <span className="number-text">$300</span> per extra call
                </p>
              </div>
            </Col>

            {/*Standard*/}
            <Col
              className="border py-3 px-1 bg-white mx-3 my-3"
              style={{ borderRadius: "1.5em" }}
            >
              <div className="border-bottom">
                <h5 className={"text-center"}>Standard</h5>
                <h1 className="price-text">
                  <span style={{ fontSize: "0.4em" }}>$</span>220
                  {/*// A hack to center the price*/}
                  <span style={{ fontSize: "0.5em", visibility: "hidden" }}>
                    $
                  </span>
                </h1>
                <p className="text-center" style={{ fontSize: "0.70em" }}>
                  CAD per month
                </p>
                <p className="text-center">Good for growing small businesses</p>
              </div>
              <div className="mt-3 ml-4">
                <p className="text-left">
                  <span className="number-text">25</span> free calls
                </p>
                <p className="text-left">
                  <span className="number-text">$250</span> per extra call
                </p>
              </div>
            </Col>

            {/*Enterprise*/}
            <Col
              className="border py-3 px-1 bg-white mx-3 my-3"
              style={{ borderRadius: "1.5em" }}
            >
              <div className="border-bottom">
                <h5 className={"text-center"}>Enterprise</h5>
                <h1 className="price-text">
                  <span style={{ fontSize: "0.4em" }}>$</span>500
                  {/*// A hack to center the price*/}
                  <span style={{ fontSize: "0.5em", visibility: "hidden" }}>
                    $
                  </span>
                </h1>
                <p className="text-center" style={{ fontSize: "0.70em" }}>
                  CAD per month
                </p>
                <p className="text-center">Good for established businesses</p>
              </div>
              <div className="mt-3 ml-4">
                <p className="text-left">
                  <span className="number-text">50</span> free calls
                </p>
                <p className="text-left">
                  <span className="number-text">$200</span> per extra call
                </p>
              </div>
            </Col>
          </Row>

          <Spacer height={"4em"} />
          <InfoRow
            isCard={true}
            content={{
              image: blackShirtBlondeHair,
              text: (
                <>
                  <h4 className="pb-2 text-center">What's included</h4>
                  <ul>
                    <li className="text-left">
                      Onboarding training for employers and employees
                    </li>
                    <li className="text-left">Email support</li>
                    <li className="text-left">Posters for your workplace</li>
                  </ul>
                </>
              ),
            }}
            showImage={!isMobile}
            imagePosition={"left"}
          />
        </div>

        <Row className="justify-content-center">
          <Link to={"/bookConsultation"} className="my-5">
            <Button xl>Schedule a Demo</Button>
          </Link>
        </Row>

        <CallToActionFooter
          leftText1={"More questions about Canary?"}
          leftText2={"Let us answer them"}
          leftButtonText={"Answer my questions"}
          leftHref={"/faq"}
          rightText1={"Still not convinced?"}
          rightText2={"There are many reasons to start using Canary"}
          rightButtonText={"Tell me why"}
          rightHref={"/why"}
        />
      </Container>
      <Spacer height={40} />
    </PageWithHeaderAndFooter>
  );
}
