import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import { Link, useParams, useHistory } from "react-router-dom";
import BackArrow from "../../BackArrow";
import { FORM_NAMES } from "line3_shared/constants/forms";
import { colors } from "../../../constants/colors";
import Button from "../../Button";

export default function OrganizationChooseReport() {
  const history = useHistory();
  const { orgId } = useParams();
  const [enabledForms, setEnabledForms] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`/api/public/organizations/${orgId}`).then((response) => {
      if (response.ok) {
        response.json().then(({ formIds }) => {
          if (formIds.length === 1) {
            history.replace(`/org/${orgId}/report/${formIds[0]}`);
          } else {
            setEnabledForms(formIds);
          }
        });
      } else {
        response.json().then((json) => {
          setError("Something went wrong. Please try again later!");
        });
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ backgroundColor: colors.white }}>
      <BackArrow to={`/org/${orgId}`} />
      <Container className="d-flex vh-100 justify-content-center align-items-center">
        <Col xs={9} className="d-flex flex-column align-items-center">
          {error && <p>{error}</p>}
          {enabledForms.map((formId) => {
            return (
              <Link to={`/org/${orgId}/report/${formId}`} className="mb-3">
                <Button>
                  {FORM_NAMES.find((f) => f.id === formId).respondentButton}
                </Button>
              </Link>
            );
          })}
          <Link className="mb-3">
            <Button disabled style={{ cursor: "not-allowed" }}>
              Create a report about other workplace issues (Coming soon)
            </Button>
          </Link>
        </Col>
      </Container>
    </div>
  );
}
