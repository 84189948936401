import React from "react";
import { Image } from "react-bootstrap";
import colorLogoWithText from "../static/canary-logo-full@3x.png";
import colorLogoWithoutText from "../static/canary-1@3x.png";
import useWindowWidth from "../hooks/useWindowWidth";

export default function Logo({
  xs,
  sm,
  md,
  lg,
  xl,
  size,
  includeText,
  className,
}) {
  const { isMobile } = useWindowWidth();
  let logoHeight = size || 75;
  if (xs) {
    logoHeight = 35;
  }
  if (sm) {
    logoHeight = 75;
  }
  if (md) {
    logoHeight = 100;
  }
  if (lg) {
    logoHeight = 160;
  }
  if (xl) {
    logoHeight = 200;
  }

  const logoWidth = includeText ? logoHeight * 2.75 : logoHeight;
  const logoImage = includeText ? colorLogoWithText : colorLogoWithoutText;

  let containerStyle = {};
  if (isMobile) {
    containerStyle = {
      maxWidth: logoWidth,
      height: "auto",
    };
  } else {
    containerStyle = { height: logoHeight, width: logoWidth };
  }

  return (
    <div className={`d-inline-flex ${className}`} style={containerStyle}>
      <Image src={logoImage} fluid />
    </div>
  );

  return (
    <div className={`d-inline-flex ${className}`}>
      <Image src={logoImage} fluid style={{ maxWidth: logoWidth }} />
    </div>
  );
}
