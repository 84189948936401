import React from "react";
import whiteShirtWoman from "../../../static/learnMore/red-hair-woman-white-shirt.png";
import beardedBlueShirtMan from "../../../static/learnMore/bearded-man-blue-shirt.png";
import ScenarioAccordion from "../LearnMore/ScenarioAccordion";

const smallStartup = {
  title: " A small startup with no HR and lots of conflict",
  image: whiteShirtWoman,
  workplace: (
    <div>
      A Fintech startup is rising to success. With a couple of angel investors
      and a lean team, they are poised to launch a premium subscription service
      within a year. Things are looking good.
    </div>
  ),
  problem: (
    <div>
      One of the two Product Leads, Skyler is constantly getting into fights
      with the COO, Raven. From Skyler's point of view, Raven is always
      dismissive of their ideas and whenever Skyler is successful, Raven never
      gives praise. To Skyler, It almost feels like harassment or bullying.
      Since it's a small team and Raven is technically responsible for HR
      issues, Skyler can only talk to the CEO about this. But the CEO and Raven
      are best friends and the co-founders of the startup, so Skyler feels like
      the CEO will always side with Raven.
    </div>
  ),
  solution: (
    <div>
      Skyler calls her Canary Advisor to have an off-the-record conversation
      about how to sort through their issues, look at the problem objectively,
      and figure out a way to resolve it. Skyler gains the tools to have an
      honest conversation with the CEO and Raven in a way that is
      non-adversarial and conducive to a more productive and healthy working
      relationship.
    </div>
  ),
};

const employeesWontSpeakUp = {
  title:
    "An organization that knows something's wrong but employees won't speak up",
  image: beardedBlueShirtMan,
  workplace: (
    <div>
      A restaurant chain with more than 30 locations requires every new hire to
      watch the same 15-minute video on workplace harassment as part of their
      training. Since employees need to maintain good relationships with
      managers to get preferred shifts, they often don't speak up when these
      types of issues arise.
    </div>
  ),
  problem: (
    <div>
      Over the last few years there have been a growing number of complaints of
      sexual harassment. Perplexingly, these complaints are only brought up
      months or even years after the incidents have occurred. The investigations
      reveal that the inappropriate comments were said by employees who just did
      not realize that what they were saying was inappropriate.
    </div>
  ),
  solution: (
    <div>
      The restaurant chain decides to purchase Canary. Soon, the employer
      representative sees that the types of calls that their Canary Advisor
      receives reveal that employees are making inappropriate comments about
      both gender and race. The restaurant chain then trains everyone on those
      two specific issues and prevents those types of comments from recurring.
    </div>
  ),
};

const scenarios = [employeesWontSpeakUp, smallStartup];

export default function HowItWorksExampleScenarios() {
  return (
    <div>
      {scenarios.map((scenario, index) => {
        return <ScenarioAccordion scenario={scenario} key={index} />;
      })}
    </div>
  );
}
