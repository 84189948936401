import React from "react";
import { Image } from "react-bootstrap";
import colorLogoWithoutText from "../../static/canary-1@3x.png";
import buildingUpLogo from "../../static/building_up_logo_bw.png";
import cfoLogo from "../../static/cfo_logo_bw.png";
import drakeLogo from "../../static/drake_logo_bw.png";
import useWindowWidth from "../../hooks/useWindowWidth";

export default function TrustedBy({}) {
  const { isMobile } = useWindowWidth();
  return (
    <div className="d-flex flex-column">
      <h4 className="text-center font-weight-bold mb-4">
        <u>Our customers</u>
      </h4>
      <div
        className={`d-flex ${
          isMobile ? "flex-column" : "flex-row"
        } justify-content-center align-items-center mx-5 `}
      >
        <Image src={drakeLogo} height={50} className="mx-5 my-3 " />
        <Image src={buildingUpLogo} height={45} className="mx-5 my-3 " />
        <Image src={cfoLogo} height={50} className="mx-5 my-3 " />
      </div>
    </div>
  );
}
