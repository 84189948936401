import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import { FORM_NAMES } from "line3_shared/constants/forms";
import isEqual from "lodash.isequal";
import Checkbox from "../../../Checkbox";
import Button from "../../../Button";

const defaultOrgFormIds = FORM_NAMES.reduce((hsh, form) => {
  return { ...hsh, [form.id]: false };
}, {});

export default function OrganizationPage(props) {
  const { orgId } = useParams();
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [organization, setOrganization] = useState({});
  const [errors, setErrors] = useState([]);
  const [orgFormIds, setOrgFormIds] = useState(defaultOrgFormIds);
  const [formIdSetResponse, setFormIdSetResponse] = useState(null);

  useEffect(() => {
    setLoading(true);
    fetch(`/api/organizations/${orgId}`).then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          setOrganization(json);
          const initialFormIds = json.formIds;
          const fetchedOrgFormIds = { ...orgFormIds };
          for (const formId of initialFormIds) {
            fetchedOrgFormIds[formId] = true;
          }
          setOrgFormIds(fetchedOrgFormIds);
        });
      } else {
        if (response.status === 401) {
          history.push("/app/login");
        } else {
          response.json().then((json) => {
            setErrors(["Something went wrong."]);
          });
        }
      }
      setLoading(false);
    });
  }, [history]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <Spinner animation="border" />;

  function toggleForm(formId) {
    setOrgFormIds({ ...orgFormIds, [formId]: !orgFormIds[formId] });
  }

  function saveOrgFormIds() {
    fetch("/api/organizationEdit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ orgId, data: { orgFormIds } }),
    })
      .then((response) => {
        if (response.ok) {
          setFormIdSetResponse("Success");
          setTimeout(() => {
            setFormIdSetResponse(null);
          }, 1500);
        } else {
          if (response.status === 401) {
            history.push("/app/login");
          } else {
            response.json().then((json) => {
              setErrors([json.message]);
            });
          }
        }
      })
      .catch((err) => {
        setErrors(["User creation failed. Please try again."]);
      });
  }

  return (
    <Container>
      <h3>{organization.name}</h3>
      <p>
        Password for respondents: <strong>{organization.password}</strong>
      </p>
      <Card>
        <Card.Header>Forms this organization has access to:</Card.Header>
        <Card.Body>
          {FORM_NAMES.map((form) => {
            const belongs = orgFormIds[form.id];
            return (
              <Checkbox
                key={form.id}
                checked={belongs}
                onChange={() => toggleForm(form.id)}
                text={form.name}
              />
            );
          })}
        </Card.Body>
        <Card.Body className="d-flex flex-row align-items">
          <Button
            disabled={isEqual(orgFormIds, defaultOrgFormIds)} // TODO: This is acting strange
            onClick={saveOrgFormIds}
          >
            Save
          </Button>
          {formIdSetResponse && (
            <p className="ml-3 text-success">{formIdSetResponse}</p>
          )}
        </Card.Body>
      </Card>
      {errors.map((err) => (
        <p key={err}>{err}</p>
      ))}
    </Container>
  );
}
