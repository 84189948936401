import React from "react";
import cx from "classnames";

export default function Card({
  children,
  className = "",
  style = {},
  ...props
}) {
  return (
    <div
      className={cx(
        "d-flex flex-column border shadow p-4 justify-content-center align-items-center",
        className
      )}
      style={{
        borderRadius: "1.5em",
        ...style,
      }}
      {...props}
    >
      {children}
    </div>
  );
}
