import React from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Spacer from "../../Spacer";
import Info from "../Info";
import "./Home.scss";
import Row from "react-bootstrap/Row";
import CallToActionFooter from "../../CallToActionFooter";
import Hero from "./Hero";
import PageWithHeaderAndFooter from "../../PageWithHeaderAndFooter";
import Button from "../../Button";

export default function Home() {
  return (
    <PageWithHeaderAndFooter>
      <Container className="d-flex flex-column justify-content-center">
        <Hero />

        {/*Main Content*/}
        <Info />

        <Row className="justify-content-center">
          <Link to="/bookConsultation" className="my-5">
            <Button xl>Schedule a Demo</Button>
          </Link>
        </Row>
        <CallToActionFooter
          leftText1={"Transparent Pricing"}
          leftButtonText={"Let's see prices"}
          leftHref={"/prices"}
          rightText1={"Got questions?"}
          rightButtonText={"Answer my questions"}
          rightHref={"/faq"}
        />
        <Spacer height={80} />
      </Container>
    </PageWithHeaderAndFooter>
  );
}
