import React from "react";
import coffeeLady from "../../static/coffee-lady-facing-right.png";
import laptopMan from "../../static/laptop-man-facing-left.png";
import anonGroup from "../../static/group-anon.png";
import laptopLady from "../../static/laptop-lady-facing-right.png";
import Spacer from "../Spacer";
import useWindowWidth from "../../hooks/useWindowWidth";
import InfoRow from "./InfoRow";
import TrustedBy from "./TrustedBy";

const infoContent = [
  {
    image: anonGroup,
    header: "A proven method for getting employees to speak up",
    text: (
      <>
        <p>
          Employees are more likely to raise concerns when they can have a
          confidential conversation with a neutral third-party
        </p>
        <p>
          Canary is just that. Canary helps employees speak up about their
          concerns by giving them an outlet to express themselves - an
          off-the-record sounding board
        </p>
      </>
    ),
  },
  {
    image: coffeeLady,
    header: "Helpful advice from someone who knows about workplace issues",
    text: (
      <>
        <p>
          When managers and employees need help with sensitive workplace issues,
          like misconduct, interpersonal conflict, or harassment, they can
          resolve them by speaking to a professional, experienced Advisor at
          Canary. Their Canary Advisor will help them understand relevant
          workplace policies and provide them with one-time advice or ongoing
          coaching.
        </p>
      </>
    ),
  },

  {
    image: laptopMan,
    header: "The data employers need to create a truly inclusive workplace",
    text: (
      <>
        <p>
          Employers get access to the Canary Dashboard, which contains helpful
          data about concerns raised by employees without revealing their
          identity. Employers can improve their workplace culture by using this
          data to measure diversity and inclusion-related KPIs and create
          targeted training programs based on employees' concerns.
        </p>
      </>
    ),
  },
  {
    image: laptopLady,
    header: "Reduces the legal and emotional costs of workplace problems",
    text: (
      <>
        <p>
          Systems like Canary have been proven to reduce the number of formal
          complaints in workplaces. Why? Because Canary helps employees resolve
          their concerns informally - if they want to - instead of filing a
          formal complaint that could lead to costly, time-consuming
          investigations.
        </p>
      </>
    ),
  },
];

export default function Info() {
  const { isMobile } = useWindowWidth();
  return (
    <div className="d-flex flex-column">
      <div className="border border-dark rounded p-4 mx-5">
        <p>
          Canary is a turnkey ombuds<sup>(1)</sup> system for reporting and
          exploring options for resolving workplace problems such as:
        </p>
        <div>
          <ul>
            <li>interpersonal conflict</li>
            <li>harassment</li>
            <li>bias</li>
            <li>discrimination</li>
            <li>ethical dilemmas</li>
            <li>unfair treatment</li>
          </ul>
        </div>
        <p>
          Canary offers employees conflict coaching and consultation with the
          goal of empowering them to either handle issues themselves or report
          their concerns in a way that makes sense for them. Canary gives
          employers insights about these issues in their workplace while
          protecting employees' identity
        </p>
        <p className="text-sm-center" style={{ fontSize: "0.75em" }}>
          (1) An ombuds is a designated "neutral" person who is appointed by an
          organization to address concerns of employees and managers. Most large
          organizations like banks and universities have them.
        </p>
      </div>
      <Spacer height={150} />
      <TrustedBy />
      <Spacer height={150} />
      <div className={`${isMobile ? "mx-2" : ""}`}>
        <InfoRow
          isCard={true}
          imagePosition="right"
          content={infoContent[0]}
          showImage={!isMobile}
          mirrorImageY={true}
        />
        <InfoRow
          isCard={true}
          imagePosition="left"
          content={infoContent[1]}
          showImage={true}
        />
        <InfoRow
          isCard={true}
          imagePosition="right"
          content={infoContent[2]}
          showImage={true}
        />
        <InfoRow
          isCard={true}
          imagePosition="left"
          content={infoContent[3]}
          showImage={true}
        />
      </div>
    </div>
  );
}
