import { forms } from "line3_shared/constants/forms";
import { FilterType } from "line3_shared/types/FilterType";
import React, { useEffect } from "react";
import { concernsColorPalette } from "../../../../../constants/colors";
import useGetDataFromQuery from "../../../../../hooks/useGetDataFromQuery";
import Doughnut2 from "../../../../charts/Doughnut2";
import HorizontalBar2 from "../../../../charts/HorizontalBar2";
import Loader from "../../../../Loader";
import Spacer from "../../../../Spacer";

export default function WorkplaceCultureIssuesTab({ updateRespondentCounter }) {
  const workplaceCultureIssuesFilter: FilterType = {
    or: [{ questionId: "81iLlOKLMEr2", answerId: "flvYqsoERsWb" }],
  };

  const { loading, data } = useGetDataFromQuery({
    formId: forms.shortenedForm,
    questionId: "81iLlOKLMEr2",
    filter: workplaceCultureIssuesFilter,
  });

  useEffect(() => {
    if (data) {
      updateRespondentCounter(data.total);
    }
  }, [updateRespondentCounter, data]);

  if (loading) return <Loader />;

  return (
    <div>
      <HorizontalBar2
        title="Topic of Concerns and Feedback"
        palette={concernsColorPalette}
        query={{
          formId: forms.shortenedForm,
          questionId: "RmfE983j3YGT",
          filter: workplaceCultureIssuesFilter,
          excludeAnswerIds: ["XrL02Gk17K1I"], // Exclude "None of the above"
        }}
      />
      <Spacer height={40} />
      <HorizontalBar2
        title="Transgressor"
        palette={concernsColorPalette}
        query={{
          formId: forms.shortenedForm,
          questionId: "7kKrhvt40hRq",
          filter: workplaceCultureIssuesFilter,
        }}
      />
      <Spacer height={40} />
      <HorizontalBar2
        title="Allegations of Discrimination based on:"
        palette={concernsColorPalette}
        query={{
          formId: forms.shortenedForm,
          questionId: "r5qaYiiRAkDR",
          filter: workplaceCultureIssuesFilter,
          excludeAnswerIds: ["tskIONkOcGux"], // Exclude "None of the above"
        }}
      />
      <Spacer height={40} />
      <div className="d-flex flex-row justify-content-between">
        <Doughnut2
          title={"One-time or Ongoing Issue"}
          query={{
            formId: forms.shortenedForm,
            questionId: "PpHsLIrUFKq3",
            filter: workplaceCultureIssuesFilter,
          }}
        />
        <Doughnut2
          title={"Previously Reported to Management"}
          query={{
            formId: forms.shortenedForm,
            questionId: "wIP0ordQCY7b",
            filter: workplaceCultureIssuesFilter,
          }}
        />
      </div>
    </div>
  );
}
