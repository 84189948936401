import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import { Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import BackArrow from "../../BackArrow";
import Spacer from "../../Spacer";
import { colors } from "../../../constants/colors";
import {
  CHICKEN_FARMERS_OF_ONTARIO_ORG_ID,
  DRAKE_HOTEL_PROPERTIES_ORG_ID,
  VLAD_LOCAL_TEST_COMPANY_ORG_ID,
} from "../../../constants/organizations";
import Button from "../../Button";

const canaryCoachFeatureEnabledMap = {
  [DRAKE_HOTEL_PROPERTIES_ORG_ID]: true,
  [VLAD_LOCAL_TEST_COMPANY_ORG_ID]: true,
  [CHICKEN_FARMERS_OF_ONTARIO_ORG_ID]: false,
};

export default function OrganizationHome() {
  const [showExplanation, setShowExplanation] = useState(false);
  const { orgId } = useParams();

  const canaryCoachFeatureEnabled = canaryCoachFeatureEnabledMap[orgId];

  return (
    <div style={{ backgroundColor: colors.white }}>
      <BackArrow to="/anonymousReport" />
      <Container className="d-flex vh-100 justify-content-center align-items-center">
        <Col xs={9} className="d-flex flex-column align-items-center">
          <Link to={`/org/${orgId}/chooseReport`} className="mb-3">
            <Button>Create a Report</Button>
          </Link>
          <Link to="/bookCoachingSession" className="mb-3">
            <Button
              disabled={!canaryCoachFeatureEnabled}
              style={{
                cursor: `${
                  canaryCoachFeatureEnabled ? "pointer" : "not-allowed"
                }`,
              }}
            >
              Call a Canary Coach
            </Button>
          </Link>
          <Spacer height={40} />
          <Button
            variant="outline-info"
            onClick={() => setShowExplanation(!showExplanation)}
          >
            How is this anonymous?
          </Button>
          <div style={{ height: "100px" }} className="mt-3">
            {showExplanation && (
              <>
                <p className="text-center">
                  Your report will be anonymous. Remember, you are using the
                  same password as every other member of your organization, so
                  no one will know that you created this report.
                </p>
                <p className="text-center">
                  If you are concerned about Canary having access to your IP
                  address, click{" "}
                  <a
                    href="https://nordvpn.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>{" "}
                  to install a tool to mask it.
                </p>
              </>
            )}
          </div>
        </Col>
      </Container>
    </div>
  );
}
