import React, { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { DateRangePicker } from "react-date-range";
import { colors } from "../constants/colors";
import DateRangeContext from "../contexts/DateRangeContext";
import Button from "./Button";

const allDatesRange = {
  startDate: new Date("1960-01-01"),
  endDate: new Date("2100-01-01"),
};

const lastMonthRange = {
  startDate: new Date().setMonth(new Date().getMonth() - 1),
  endDate: new Date(),
};

export default function DateRangeButtonAndModal() {
  const [showModal, setShowModal] = useState(false);
  const [unconfirmedDateRange, setUnconfirmedDateRange] = useState(
    allDatesRange
  );
  const { dateRange, setDateRange } = useContext(DateRangeContext);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => {
    setShowModal(true);
  };

  const startDate = dateRange.startDate.toISOString().slice(0, 10);
  const endDate = dateRange.endDate.toISOString().slice(0, 10);
  const isAllDateRange =
    dateRange.startDate.getDate() === allDatesRange.startDate.getDate() &&
    dateRange.endDate.getDate() === allDatesRange.endDate.getDate();

  const dateRangeText = isAllDateRange ? `All` : `${startDate} to ${endDate}`;
  const initialFocusedRange = isAllDateRange
    ? lastMonthRange
    : unconfirmedDateRange;

  function onConfirm() {
    setDateRange(unconfirmedDateRange);
  }

  return (
    <>
      <Button
        style={{
          backgroundColor: colors.secondaryBlue,
          border: "1px solid lightgrey",
          fontSize: 12,
        }}
        onClick={handleShowModal}
      >
        Dates: {dateRangeText}
      </Button>
      <Modal show={showModal} onHide={handleCloseModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Date Range Picker</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DateRangePicker
            onChange={(item) => {
              setUnconfirmedDateRange(item.range1);
            }}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            shownDate={new Date()}
            ranges={[unconfirmedDateRange]}
            direction="horizontal"
            initialFocusedRange={[initialFocusedRange]}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button
            variant="info"
            onClick={() => {
              setDateRange(allDatesRange);
              handleCloseModal();
            }}
          >
            All Dates
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              onConfirm();
              handleCloseModal();
            }}
          >
            Apply Selected Dates
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
