import React from "react";
import LibButton from "react-bootstrap/Button";
import cx from "classnames";

export default function Button({ xl = false, ...props }) {
  // xl is not a bootstrap size, so we add a custom implementation for it here
  let buttonClassName;
  if (xl) {
    buttonClassName = "px-5 py-3";
  }
  const buttonSize = xl ? "lg" : props?.size;

  return (
    <LibButton
      {...props}
      size={buttonSize}
      className={cx("shadow-lg px-4", buttonClassName, props.className)}
      style={{ borderRadius: "1.5em" }}
    />
  );
}
