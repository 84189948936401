import * as React from "react";
import SimpleAccordion from "../../SimpleAccordion";

export default function ComparisonCaseStudy({}) {
  return (
    <div>
      <h1>Case Study</h1>
      <p>
        See how things would turn out differently if an employer used{" "}
        <span>
          <strong>Canary</strong>
        </span>{" "}
        instead of a conventional web-based anonymous complaint platform
      </p>
      <div className="py-3">
        <h4>
          <strong>The problem:</strong>
        </h4>
        <p>
          An employee is upset by some of the comments said by their colleague -
          the company's CFO. The CFO chastised an employee's work in front of
          others at a meeting, and later, at a work event, the CFO made a joke
          that another employee found offensive. The CFO is best friends with
          the Director of HR.
        </p>
      </div>
      <SimpleAccordion
        title={"What happens when you have an anonymous complaint platform?"}
        paragraph1={
          <>
            <ol>
              <li>
                The employee fills out a form on their employer's anonymous
                complaint platform (annual cost $2,000). The complaint says that
                they feel harassed by the CFO because of inappropriate comments
                he said at a meeting and at a work event. The employee includes
                the CFO's name in the form and gives some details about when
                these events occurred.
              </li>
              <li>
                The employer receives the complaint and determines that the
                anonymous complaint could be considered harassment or sexual
                harassment and consults with an employment lawyer ($1,000).
                Their employment lawyer suggests that they hire an investigator
                to investigate the anonymous complaint.
              </li>
              <li>
                During the investigation, all employees who were at the meeting
                and the work event cited in the complaint, including the
                employee who submitted the anonymous complaint, are called in
                for interviews with the investigator. Everyone talks to each
                other after they are interviewed, even though they were told not
                to. They realize that the person who was the target of the
                offensive joke didn't even raise the anonymous complaint. The
                person who was the target of the offensive joke did not think it
                was that offensive.
              </li>
              <li>
                The CFO hires a lawyer to advise him during the investigation.
                He is upset about not being able to talk to anyone and that he
                does not know who raised the complaint.
              </li>
              <li>
                The investigator completes the investigation in three months and
                submits a report along with a final bill ($10,000). The
                investigator concludes that even though the behaviour that
                occurred did not constitute harassment or sexual harassment, it
                was not respectful and the CFO should receive training.
              </li>
              <li>
                The CFO is deeply upset about being forced to go through the
                investigation and not being able to speak to colleagues. The CFO
                feels as though their character has been put into question and
                resigns.
              </li>
              <li>
                The team that reported to the CFO is deeply divided as a result
                of the investigation. Some are upset with the person who they
                believe raised the complaint about the CFO, others are relieved
                that the CFO has left. The employer decides to hire a workplace
                restoration facilitator to address this rift in the group
                ($5,000).
              </li>
              <li>
                After the workplace restoration, half of the team leaves the
                employer and joins the CFO at the CFO's new company.
              </li>
              <li>
                The employee who submitted the anonymous complaint feels
                terrible for instigating the investigation, and eventually
                leaves the company.
              </li>
            </ol>
          </>
        }
      />
      <SimpleAccordion
        title={"What happens when you have Canary?"}
        paragraph1={
          <>
            <ol>
              <li>
                The employee calls their Canary Advisor and talks about their
                concerns regarding the CFO's behaviour. The employee says that
                she does not want to speak to HR because of the close
                relationship between the CFO and the Director of HR.
              </li>
              <li>
                The Canary Advisor talks to the employee about her options for
                addressing this behaviour under their workplace policy,
                including informal resolution or raising a formal complaint. The
                Canary Advisor explains that even if the employee is anonymous,
                if they name the CFO in any formal complaint, there may be a
                formal investigation into the complaint and the employee may be
                interviewed as part of the investigation.
              </li>
              <li>
                The Canary Advisor suggests that one option might be for the
                employee to speak directly to the CFO. The employee is concerned
                about experiencing retaliation, and is unsure whether they have
                the courage to have that kind of a conversation. The Canary
                Advisor role-plays the conversation with the employee, and the
                employee decides to take some time to consider whether to take
                this approach to addressing their concerns.
              </li>
              <li>
                The employee eventually decides to have a conversation with the
                CFO. The conversation goes well and the CFO expresses gratitude
                for the employee's courage in raising concerns. They continue to
                have a positive working relationship and they agree to keep
                their conversation confidential.
              </li>
              <li>
                The Canary Advisor submits a report to the employer that
                includes a basic description of the type of concern that was
                raised - inappropriate comments about one's work and an
                offensive joke. No names and no specifics about where and when
                this behaviour occurred - just the behaviour itself.
              </li>
              <li>
                The employer ensures that the next annual training, they make
                sure to tell employees that even when people are exposed to
                inappropriate behaviour.
              </li>
            </ol>
          </>
        }
      />
      <SimpleAccordion
        title={"What's the difference?"}
        paragraph1={
          <>
            <h5>With a conventional anonymous complaint system:</h5>
            <p>
              {" "}
              $18,000 spent, ruined working relationships between colleagues,
              and several employees leaving the organization.
            </p>
          </>
        }
        paragraph2={
          <>
            <h5>With Canary:</h5>
            <p>
              {" "}
              $1,250 spent, improved working relationships between colleagues,
              and helpful data for employee training.
            </p>
          </>
        }
      />
    </div>
  );
}
