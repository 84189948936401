import React from "react";
import { Link } from "react-router-dom";
import "./CallToActionFooter.scss";
import Button from "./Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useWindowWidth from "../hooks/useWindowWidth";

export default function CallToActionFooter({
  leftText1,
  leftText2,
  leftButtonText,
  leftHref,
  rightText1,
  rightText2,
  rightButtonText,
  rightHref,
}) {
  const { isMobile } = useWindowWidth();
  return (
    <div className="cta-wrapper d-flex flex-row align-items-center justify-content-around w-100 pt-5">
      <Row className={`${isMobile ? "" : "w-100"}`}>
        {/*Left CTA*/}
        <Col
          className={`cta-container d-flex flex-column align-items-center p-4 border mr-md-3 shadow ${
            isMobile ? "my-2 mx-3" : ""
          }`}
          style={{ flex: 1 }}
        >
          <h5 className="text-center">{leftText1}</h5>
          {leftText2 && <h6 className="text-center">{leftText2}</h6>}
          <Link to={leftHref}>
            <Button
              size="md"
              className="call-to-action-button mt-3 bg-black text-white border border-dark shadow-lg "
            >
              {leftButtonText}
            </Button>
          </Link>
        </Col>

        {/*Right CTA*/}
        <Col
          className={`cta-container d-flex flex-column align-items-center p-4 border ml-md-3 shadow ${
            isMobile ? "my-2 mx-3" : ""
          }`}
          style={{ flex: 1 }}
        >
          <h5 className="text-center">{rightText1}</h5>
          {rightText2 && <h6 className="text-center">{rightText2}</h6>}
          <Link to={rightHref} className="mt-3">
            <Button
              size="md"
              className="call-to-action-button bg-black text-white border border-dark shadow-lg "
            >
              {rightButtonText}
            </Button>
          </Link>
        </Col>
      </Row>
    </div>
  );
}
