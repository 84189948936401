import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Container, Col, Alert } from "react-bootstrap";
import BackArrow from "../BackArrow";
import useWindowWidth from "../../hooks/useWindowWidth";
import { colors } from "../../constants/colors";
import Logo from "../Logo";
import "../FormField.scss";

export default function RespondentLogin() {
  let history = useHistory();
  const [errors, setErrors] = useState([]);
  const { isMobile } = useWindowWidth();

  function submitForm(event) {
    event.preventDefault();

    const formInputs = event.target.elements;
    const body = {
      password: formInputs.formPassword.value,
    };

    fetch("/api/organizationLogin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            history.push(`/org/${json.orgId}`);
          });
        } else {
          response.json().then((json) => {
            setErrors([json.message]);
            setTimeout(() => {
              setErrors([]);
            }, 3000);
          });
        }
      })
      .catch((err) => {
        setErrors(["Invalid password. Please try again."]);
        setTimeout(() => {
          setErrors([]);
        }, 3000);
      });
  }

  return (
    <div style={{ backgroundColor: colors.white }}>
      <BackArrow to="/" />
      <Container className="d-flex vh-100 justify-content-center align-items-center">
        <Col
          xs={12}
          className="d-flex flex-column align-items-center justify-content-center"
        >
          <Logo lg />

          <Form onSubmit={submitForm}>
            <div className="d-flex flex-column">
              <Form.Group controlId="formPassword">
                <Form.Control
                  className="text-center customBorder"
                  type="password"
                  placeholder={
                    isMobile
                      ? "Company password"
                      : "Enter the password provided by your company here"
                  }
                  autoFocus="autofocus"
                />
              </Form.Group>

              <div className="d-flex justify-content-center">
                <Button type="submit">Next</Button>
              </div>
              <p className="mt-4 text-center">
                Look for the password in your company's policies or search for
                "Canary" in your work email.
              </p>
              <p className="mt-5 text-center">
                If you don't have a password, then your company does not use
                Canary.
              </p>
              <p className="mt-1 text-center">
                But we'd still like to hear about your concerns -{" "}
                <a href="/freeWorkplaceSurvey">click here</a> to tell us about
                them (anonymously).
              </p>
              <p className="mt-1 text-center">
                If you think your company should use Canary, then{" "}
                <a href="/interestedRespondent">click here</a> to help us get in
                touch.
              </p>
            </div>
          </Form>
          <div className="mt-3" style={{ height: "100px" }}>
            {errors.length > 0 &&
              errors.map((error, index) => {
                return (
                  <Alert key={index} variant={"danger"}>
                    {error}
                  </Alert>
                );
              })}
          </div>
        </Col>
      </Container>
    </div>
  );
}
