import CallToActionFooter from "../CallToActionFooter";
import Container from "react-bootstrap/Container";
import React from "react";
import HowItWorksExampleScenarios from "./HowItWorks/HowItWorksExampleScenarios";
import AppHeader from "../AppHeader";
import suitMan from "../../static/suit-man-facing-left.png";
import anonGroup from "../../static/group-anon.png";
import laptopMan from "../../static/laptop-man-facing-left.png";
import coffeeLady from "../../static/coffee-lady-facing-right.png";
import InfoRow from "./InfoRow";
import useWindowWidth from "../../hooks/useWindowWidth";
import { Link } from "react-router-dom";
import PageWithHeaderAndFooter from "../PageWithHeaderAndFooter";
import Button from "../Button";
import whiteShirtWoman from "../../static/learnMore/red-hair-woman-white-shirt.png";
import laptopLady from "../../static/laptop-lady-facing-right.png";

const CONTENT = [
  {
    image: whiteShirtWoman,
    text: (
      <>
        <p>
          It all starts with a concern. A potential violation of a workplace
          policy, interpersonal conflict, bullying, or harassment. Anything that
          makes an employee feel like something isn't right.
        </p>
        <p>
          If an employee wants to stay anonymous or doesn't feel comfortable
          immediately reporting the concern to their manager or HR, they can
          contact their Canary Advisor.
        </p>
      </>
    ),
  },
  {
    image: laptopLady,
    text: (
      <>
        <p>
          Employees can either call their Canary Advisor or book an appointment
          with their Canary Advisor on Canary's website.
        </p>
      </>
    ),
  },

  {
    image: suitMan,
    text: (
      <>
        <p>
          During the call, the Canary Advisor will ask employees some questions
          about their concerns and how they want them resolved, as well as any
          information that the employee wants to provide to their employer about
          their concern. Aside from this information that the employee chooses
          to provide, the call will be entirely confidential and off-the-record.
        </p>
        <p>
          The Canary Advisor may review relevant workplace policies with
          employees to help them figure out how to address their concerns. The
          Canary Advisor will also offer ongoing coaching as well as other
          formal and informal avenues to address their concerns.
        </p>
      </>
    ),
  },
  {
    image: laptopMan,
    text: (
      <>
        <p>
          Once the call is over, an employer representative will receive a
          notification. The representative can log into their Canary Dashboard
          and see the type of concern that was raised during the call, as well
          as some insights on how the concern may be addressed, and how the
          employer can prevent similar concerns from happening again.
        </p>
      </>
    ),
  },
];

export default function HowItWorks() {
  const { isMobile } = useWindowWidth();
  return (
    <PageWithHeaderAndFooter>
      <Container className="d-flex flex-column justify-content-center py-5">
        <div className="d-flex justify-content-center py-5">
          <h1>Here's How Canary Works</h1>
        </div>

        <InfoRow
          isCard={false}
          imagePosition="right"
          content={CONTENT[0]}
          showImage={!isMobile}
          mirrorImageY={true}
        />
        <InfoRow
          isCard={false}
          imagePosition="left"
          content={CONTENT[1]}
          showImage={!isMobile}
        />
        <InfoRow
          isCard={false}
          imagePosition="right"
          content={CONTENT[2]}
          showImage={!isMobile}
        />
        <InfoRow
          isCard={false}
          imagePosition="left"
          content={CONTENT[3]}
          showImage={!isMobile}
          mirrorImageY={true}
        />
        <div className="d-flex justify-content-center py-5">
          <Link to="/bookConsultation" className="mb-3">
            <Button xl>Schedule a Demo</Button>
          </Link>
        </div>
        <div>
          <h2>Case Studies</h2>
          <HowItWorksExampleScenarios />
        </div>
        <CallToActionFooter
          leftText1={"More questions about Canary?"}
          leftButtonText={"Let us answer them"}
          leftHref={"/faq"}
          rightText1={"Transparent Pricing"}
          rightButtonText={"Let's see prices"}
          rightHref={"/prices"}
        />
      </Container>
    </PageWithHeaderAndFooter>
  );
}
