import * as React from "react";
import AppHeader from "./AppHeader";
import BookCalendlyAppointment from "./pages/BookCalendlyAppointment";
import Footer from "./Footer";

type Props = { children: React.ReactNode };

export default function PageWithHeaderAndFooter({ children }: Props) {
  return (
    <div className="d-flex flex-column min-vh-100">
      <AppHeader />
      <div style={{ flex: 1 }}>{children}</div>
      <Footer />
    </div>
  );
}
