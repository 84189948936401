import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Spacer from "../Spacer";
import { SOURCE_TYPE } from "../../constants/leads";
import "../FormField.scss";
import Button from "../Button";

// const learnMoreOptions = ["Pricing", "How Canary works", "Booking a Demo"];

export default function LeadsFormForRespondents() {
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");

  function submitForm(event) {
    setErrors([]);
    setSuccessMessage("");

    event.preventDefault();

    const formInputs = event.target.elements;

    const body = {
      company: formInputs.formBasicCompany.value,
      name: formInputs.formBasicName.value,
      email: formInputs.formBasicEmail.value,
      city: formInputs.formBasicCity.value,
      country: formInputs.formBasicCountry.value,
      sourceType: SOURCE_TYPE.RESPONDENT,
    };

    if (!body.email) {
      setErrors(["Company email address cannot be blank"]);
      return;
    } else if (!body.company) {
      setErrors(["Company name cannot be blank"]);
      return;
    }

    fetch("/api/leads", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            setSuccessMessage("Thank you for providing your company details.");
          });
        } else {
          response.json().then((json) => {
            setErrors([json.message]);
          });
        }
      })
      .catch((err) => {
        setErrors(["Something went wrong. Please try again."]);
      });
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <Form onSubmit={submitForm}>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <Row className="justify-content-center align-items-center mb-2">
            <Form.Group
              controlId="formBasicCompany"
              className="mb-0"
              style={{ width: 220 }}
            >
              <Form.Control
                type="text"
                placeholder="Company Name"
                className="customBorder"
              />
            </Form.Group>
          </Row>

          <Row className="justify-content-center align-items-center mb-2">
            <Form.Group
              controlId="formBasicName"
              className="mb-0"
              style={{ width: 220 }}
            >
              <Form.Control
                type="text"
                placeholder="Company Contact Person"
                className="customBorder"
              />
            </Form.Group>
          </Row>

          <Row className="justify-content-center align-items-center mb-2">
            <Form.Group
              controlId="formBasicEmail"
              className="mb-0"
              style={{ width: 220 }}
            >
              <Form.Control
                type="text"
                placeholder="Company Email Address"
                className="customBorder"
              />
            </Form.Group>
          </Row>

          <Row className="justify-content-center align-items-center mb-2">
            <Form.Group
              controlId="formBasicCity"
              className="mb-0"
              style={{ width: 220 }}
            >
              <Form.Control
                type="text"
                placeholder="City"
                className="customBorder"
              />
            </Form.Group>
          </Row>

          <Row className="justify-content-center align-items-center mb-2">
            <Form.Group
              controlId="formBasicCountry"
              className="mb-0"
              style={{ width: 220 }}
            >
              <Form.Control
                type="text"
                placeholder="Country"
                className="customBorder"
              />
            </Form.Group>
          </Row>

          <Spacer height={40} />
          <Button type="submit">Submit</Button>
        </div>
      </Form>
      <div className="mt-3">
        {errors.length > 0 &&
          errors.map((error, index) => {
            return (
              <Alert key={index} variant={"danger"}>
                {error}
              </Alert>
            );
          })}
        {successMessage.length > 0 && (
          <Alert variant="success">{successMessage}</Alert>
        )}
      </div>
    </div>
  );
}
