import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import React from "react";
import "./InfoRow.scss";
import useWindowWidth from "../../hooks/useWindowWidth";

export default function InfoRow({
  content,
  imagePosition = "left",
  showImage = true,
  mirrorImageY = false,
  isCard = false,
}) {
  const { isMobile } = useWindowWidth();
  const imageStyle = { maxHeight: 300 };
  if (mirrorImageY) {
    imageStyle.transform = "scaleX(-1)";
  }

  const rowClassName = `d-flex ${
    imagePosition === "left" ? "flex-row-reverse" : ""
  } ${
    !isMobile && isCard ? "px-5 py-4 border card-container shadow" : ""
  } w-md-100`;

  return (
    <Row style={{ marginBottom: 50 }} className={rowClassName}>
      <Col
        xs={12}
        md={8}
        lg={6}
        className="d-flex flex-column justify-content-center"
      >
        <h2 className="my-3">{content.header}</h2>
        {content.text}
      </Col>
      {showImage && (
        <Col
          className="d-flex justify-content-center align-items-center mt-3"
          xs={12}
          md={4}
          lg={6}
        >
          <Image fluid src={content.image} style={imageStyle} />
        </Col>
      )}
    </Row>
  );
}
