import * as React from "react";
import Logo from "../../Logo";
import { Link } from "react-router-dom";
import Button from "../../Button";

export default function Hero({}) {
  return (
    <div className="px-4 py-5 my-5 text-center">
      <Logo xl includeText className="mb-3 justify-content-center" />
      <div className="mx-auto">
        <p className="lead mb-4">
          A platform for your employees to raise concerns anonymously and get
          independent, helpful advice for managing difficult situations at work.
        </p>
        <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
          <Link to="/how" className="mb-3">
            <Button xl>See how it works</Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
